import { fields, orderingAndLimit } from "./variables"

export const groqFeed = (type: string) => {
  const filter = `_type == '${type}' && visibility == true`

  const { getFirstPage, getNextPage } = queries(
    filter,
    orderingAndLimit,
    fields,
  )

  return {
    filter,
    getFirstPage,
    getNextPage,
  }
}
